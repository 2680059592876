import React, { FormEvent } from "react"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../utils/regex"
import Button from "../Button"
import FormInput from "../FormInput"
import InfoMsg, { InfoMsgType } from "../InfoMsg"
import "./styles.css"
import {
  getContracts,
  getUserInfo,
  saveContracts,
  saveUserInfo,
} from "../../utils/services/keepAuth"
import { putUserInfo, putValideUserInfo } from "../../utils/api/userInfo"
import { isUserCasnic } from "../../utils"
import userBlurredBackground from "../Hooks/useBlurredBackground"
import { useStopBodyScrolling } from "../Hooks/useStopBodyScrolling"
import { useStopBodyScrollingWithCondition } from "../Hooks/useStopBodyScrollingWithCondition"

type VerificareInfoFormData = {
  isCasnic: boolean
  denumire: string
  adresaLocConsum: string
  telefon: string
  telefonUrgente?: string
  emailSecundar?: string
}

const initialFormData: VerificareInfoFormData = {
  isCasnic: false,
  denumire: "",
  adresaLocConsum: "",
  telefon: "",
  telefonUrgente: "",
  emailSecundar: "",
}

const checkFormData = (formData: VerificareInfoFormData) => {
  return (
    ALFA_NUMERIC_REGEX(formData.denumire) &&
    ALFA_NUMERIC_REGEX(formData.adresaLocConsum) &&
    PHONENUMBER_REGEX(formData.telefon) &&
    (formData.telefonUrgente === "" ||
      PHONENUMBER_REGEX(formData.telefonUrgente)) &&
    (formData.emailSecundar === "" || EMAIL_REGEX(formData.emailSecundar))
  )
}

type LocalUserInfo = {
  denumire: string
  adresaClc: string
  telefon: string
  telUrgente: string
  emailSecundar: string
}

const checkFormDataModified = (
  localUserInfo: LocalUserInfo | null,
  formData: VerificareInfoFormData
) => {
  return (
    localUserInfo != null &&
    (localUserInfo.denumire != formData.denumire ||
      localUserInfo.adresaClc != formData.adresaLocConsum ||
      localUserInfo.telefon != formData.telefon ||
      localUserInfo.telUrgente != formData.telefonUrgente ||
      localUserInfo.emailSecundar != formData.emailSecundar)
  )
}
export default function VerificareInfoClientPopup() {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })

  const [showPopup, setShowPopup] = React.useState(false)

  const [formData, setFormData] =
    React.useState<VerificareInfoFormData>(initialFormData)

  const initialUserData = React.useRef<LocalUserInfo | null>(null)

  userBlurredBackground({ showPopup, querySelector: ".dashContainer" })
  // useStopBodyScrollingWithCondition({ shouldStopScrolling: showPopup })

  React.useEffect(() => {
    const userLocalInfo = getUserInfo()
    const contract = getContracts() != null ? getContracts()[0] : null
    if (userLocalInfo != null && contract != null) {
      if (userLocalInfo.needsInfoVerification) {
        setShowPopup(true)
        initialUserData.current = {
          denumire: userLocalInfo.denumire,
          adresaClc: contract.adresaClc,
          telefon: userLocalInfo.telefon,
          telUrgente: userLocalInfo.telUrgente,
          emailSecundar: userLocalInfo.emailSecundar,
        }
        setFormData({
          isCasnic: isUserCasnic(userLocalInfo),
          denumire: userLocalInfo.denumire,
          adresaLocConsum: contract.adresaClc,
          telefon: userLocalInfo.telefon,
          telefonUrgente: userLocalInfo.telUrgente ?? "",
          emailSecundar: userLocalInfo.emailSecundar ?? "",
        })
      }
    }
  }, [])

  // React.useEffect(() => {
  //   var dashContainer = document.getElementsByClassName("dashContainer")
  //   console.log(dashContainer[0])
  //   if (showPopup) dashContainer[0].classList.add("blurred")
  //   else dashContainer[0].classList.remove("blurred")
  // }, [showPopup])

  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const submitForm = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setInfoMsg({ isLoading: true, msg: "" })
      if (checkFormDataModified(initialUserData.current, formData)) {
        const apiResponse = await putUserInfo({
          denumire: formData.denumire,
          telefon: formData.telefon,
          telUrgente: formData.telefonUrgente,
          emailSecundar: formData.emailSecundar,
          adresaClc: formData.adresaLocConsum,
        })
        if (apiResponse.type) {
          setInfoMsg({
            isLoading: false,
            status: "ok",
            msg: "Datele au fost modificate cu succes. În câteva momente formularul va dispărea.",
          })
          saveUserInfo({
            ...getUserInfo(),
            denumire: formData.denumire,
            telefon: formData.telefon,
            telUrgente: formData.telefonUrgente,
            emailSecundar: formData.emailSecundar,
            needsInfoVerification: false,
          })
          saveContracts([
            { ...getContracts[0], adresaClc: formData.adresaLocConsum },
          ])
          setTimeout(() => {
            setShowPopup(false)
          }, 4000)
        } else
          setInfoMsg({
            isLoading: false,
            status: "error",
            msg: "A apărut o eroare la modificarea datelor. Vă rugăm să încercați din nou.",
          })
      } else {
        const apiResponse = await putValideUserInfo()
        if (apiResponse.type) {
          setInfoMsg({
            isLoading: false,
            status: "ok",
            msg: "Mulțumim de confirmare. În câteva momente formularul va dispărea.",
          })
          saveUserInfo({ ...getUserInfo(), needsInfoVerification: false })
          setTimeout(() => {
            setShowPopup(false)
          }, 4000)
        } else
          setInfoMsg({
            isLoading: false,
            status: "error",
            msg: "A apărut o eroare la confirmare datelor. Vă rugăm să încercați din nou.",
          })
      }
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare la confirmare datelor. Vă rugăm să încercați din nou.",
      })
      console.error(error)
    }
  }

  console.log(initialUserData.current, formData)
  if (showPopup)
    return (
      <div key={2} className="verificareInfoClientContainer">
        <div className="formPopup">
          <h1 className="colorOrangeDinu">
            Verifică și schimbă datele contului tău
          </h1>
          <p className="text">
            Dacă datele nu sunt corecte sau nu mai sunt de actualitate, te rugăm
            să le schimbi din acest ecran. Le poți schimba oricând si din
            Setările Contului.
          </p>
          <form
            id="verificareInfoClientForm"
            className="newStyleForm"
            onSubmit={submitForm}
          >
            <FormInput
              containerClassName="newStyleInput"
              className="textTransformUppercase"
              type="text"
              label={
                formData.isCasnic ? "Nume și prenume" : "Denumire societate"
              }
              name="denumire"
              onChange={handleInputChange}
              value={formData.denumire}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
            />
            <FormInput
              containerClassName="newStyleInput"
              className="textTransformUppercase"
              type="text"
              label="Adresă loc consum"
              name="adresaLocConsum"
              onChange={handleInputChange}
              value={formData.adresaLocConsum}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
            />
            <FormInput
              containerClassName="newStyleInput"
              type="phone"
              label="Telefon"
              name="telefon"
              onChange={handleInputChange}
              value={formData.telefon}
              errorTest={PHONENUMBER_REGEX}
              errorMessage={PHONENUMBER_ERROR}
            />
            <FormInput
              containerClassName="newStyleInput"
              type="phone"
              label="Telefon urgențe"
              name="telefonUrgente"
              onChange={handleInputChange}
              value={formData.telefonUrgente}
              errorTest={PHONENUMBER_REGEX}
              errorMessage={
                formData.telefonUrgente != null &&
                formData.telefonUrgente.length > 0
                  ? PHONENUMBER_ERROR
                  : ""
              }
              isRequired={false}
            />

            <FormInput
              containerClassName="newStyleInput"
              type="email"
              label="Email secundar"
              name="emailSecundar"
              onChange={handleInputChange}
              value={formData.emailSecundar}
              errorTest={EMAIL_REGEX}
              errorMessage={
                formData.emailSecundar != null &&
                formData.emailSecundar.length > 0
                  ? EMAIL_ERROR
                  : ""
              }
              isRequired={false}
            />

            <InfoMsg containerClassName="row" infoMsg={infoMsg} />
            {infoMsg.status !== "ok" && (
              <div className="row rowButton">
                <Button
                  isLoading={infoMsg.isLoading}
                  as="button"
                  type="submit"
                  label={
                    checkFormDataModified(initialUserData.current, formData)
                      ? "Modifcă datele"
                      : "Datele sunt corecte"
                  }
                  className="newStyleButton"
                  aspect="primary"
                  disabled={!checkFormData(formData)}
                  // onClick={submitForm}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    )
  else return null
}
